.CustomFormatView .custom-common {
  width: 880px;
  margin-bottom: 20px; }

.CustomFormatView .custom-class {
  width: 500px;
  margin-bottom: 20px; }

.CustomFormatView .tc-15-input-textarea {
  height: 168px; }

.CustomFormatView .btn-group {
  padding-top: 20px;
  border-top: 1px solid #DDDDDD; }
