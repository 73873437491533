.customFields .box {
  border: 1px solid #ddd;
  padding: 0;
  width: 765px;
  font-size: 12px; }
  .customFields .box .custom-blue {
    color: #006eff;
    font-size: 12px;
    cursor: pointer; }
  .customFields .box .mr10 {
    margin-right: 10px; }
  .customFields .box .box-head,
  .customFields .box .box-body {
    border-bottom: 1px solid #ddd; }
    .customFields .box .box-head > div,
    .customFields .box .box-body > div {
      padding: 0 10px;
      width: 33%;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .customFields .box .box-head > div:last-child > div,
      .customFields .box .box-body > div:last-child > div {
        display: inline-block;
        vertical-align: top; }
    .customFields .box .box-head .longitude-style,
    .customFields .box .box-body .longitude-style {
      margin-right: 20px; }
      .customFields .box .box-head .longitude-style .icon-valid-flag,
      .customFields .box .box-body .longitude-style .icon-valid-flag {
        display: none; }
    .customFields .box .box-head .tc-15-calendar-select,
    .customFields .box .box-body .tc-15-calendar-select {
      width: 330px; }
    .customFields .box .box-head .is-error .form-ctrl-label,
    .customFields .box .box-head .is-error .form-input-help,
    .customFields .box .box-body .is-error .form-ctrl-label,
    .customFields .box .box-body .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .customFields .box .box-head .form-input,
    .customFields .box .box-head .form-label,
    .customFields .box .box-head .form-output,
    .customFields .box .box-body .form-input,
    .customFields .box .box-body .form-label,
    .customFields .box .box-body .form-output {
      display: block;
      /* vertical-align: top; */
      padding-bottom: 0px; }
    .customFields .box .box-head .icon-valid-flag,
    .customFields .box .box-body .icon-valid-flag {
      display: none; }
    .customFields .box .box-head .tc-15-select,
    .customFields .box .box-body .tc-15-select {
      min-width: 0; }
    .customFields .box .box-head .tc-input-group,
    .customFields .box .box-body .tc-input-group {
      width: 100%;
      min-width: 0; }
    .customFields .box .box-head .tc-input-group .tc-input-group-addon,
    .customFields .box .box-body .tc-input-group .tc-input-group-addon {
      width: 33px; }
  .customFields .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .customFields .box .box-body:hover {
    background-color: #f7f7f7; }
  .customFields .box .box-body > div {
    font-weight: normal; }
  .customFields .box .box-body:last-child {
    border-bottom: none; }
  .customFields .box .form-input {
    padding-bottom: 0; }
  .customFields .box .form-input-help {
    line-height: 0;
    margin-bottom: 12px; }
  .customFields .box .tc-input-group {
    line-height: 28px; }

.customFields .no-data {
  width: 100%;
  text-align: center;
  line-height: 50px;
  border: none; }
