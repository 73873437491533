.enterprisesModal .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  width: 180px; }

.NoStatisticsconfig .hint {
  font-size: 12px;
  color: #003B80;
  line-height: 40px;
  padding-left: 20px;
  background: #E5F0FF;
  border: 1px solid #97C7FF;
  margin: 20px 0; }

.NoStatisticsconfig .name {
  font-size: 14px;
  color: #000; }
