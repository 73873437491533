.productdetail-container {
  width: 100%;
  height: 100%;
  min-width: 800px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #e2e2e2;
  background: #fff; }
  .productdetail-container .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 330px; }
  .productdetail-container .box {
    border: 1px solid #ddd;
    padding: 0;
    width: 765px;
    font-size: 12px; }
    .productdetail-container .box .box-head,
    .productdetail-container .box .box-body {
      border-bottom: 1px solid #ddd; }
      .productdetail-container .box .box-head > div,
      .productdetail-container .box .box-body > div {
        padding: 0 10px;
        width: 33%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .productdetail-container .box .box-head .form-input,
      .productdetail-container .box .box-head .form-label,
      .productdetail-container .box .box-head .form-output,
      .productdetail-container .box .box-body .form-input,
      .productdetail-container .box .box-body .form-label,
      .productdetail-container .box .box-body .form-output {
        display: block;
        /* vertical-align: top; */
        padding-bottom: 0px; }
      .productdetail-container .box .box-head .tc-15-select,
      .productdetail-container .box .box-body .tc-15-select {
        min-width: 0; }
      .productdetail-container .box .box-head .tc-input-group,
      .productdetail-container .box .box-body .tc-input-group {
        width: 100%;
        min-width: 0; }
      .productdetail-container .box .box-head .tc-input-group .tc-input-group-addon,
      .productdetail-container .box .box-body .tc-input-group .tc-input-group-addon {
        width: 33px; }
    .productdetail-container .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .productdetail-container .box .box-body:hover {
      background-color: #f7f7f7; }
    .productdetail-container .box .wd25 > div {
      width: 25%; }
    .productdetail-container .box .box-body > div {
      font-weight: normal; }
    .productdetail-container .box .box-body:last-child {
      border-bottom: none; }
    .productdetail-container .box .form-input {
      padding-bottom: 0; }
    .productdetail-container .box .form-input-help {
      line-height: 0;
      margin-bottom: 12px; }
    .productdetail-container .box .tc-input-group {
      line-height: 28px; }
  .productdetail-container .form-label label {
    width: 90px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden; }
  .productdetail-container .form-input {
    vertical-align: top; }
  .productdetail-container .extra-text {
    color: #888888;
    line-height: 18px;
    margin-top: 5px;
    display: inline-block; }
  .productdetail-container .button-after > .form-input {
    position: relative; }
    .productdetail-container .button-after > .form-input .tc-15-select {
      width: 330px; }
    .productdetail-container .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .productdetail-container .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .productdetail-container .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .productdetail-container .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
  .productdetail-container .amount-input .tc-input-group {
    width: 100px;
    min-width: 100px; }
    .productdetail-container .amount-input .tc-input-group .tc-15-input-text {
      width: 70px;
      min-width: 70px; }
    .productdetail-container .amount-input .tc-input-group .tc-input-group-addon:last-child {
      width: 30px; }
  .productdetail-container .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .productdetail-container .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .productdetail-container .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .productdetail-container .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .productdetail-container .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .productdetail-container .half-row {
    display: inline-block;
    vertical-align: top;
    margin-bottom: -20px; }
    .productdetail-container .half-row .form-input .tc-15-input-text,
    .productdetail-container .half-row .form-input .tc-15-select {
      width: 160px;
      min-width: 100px; }
  .productdetail-container .label10 .form-label label {
    width: 0px;
    padding-right: 10px; }
  .productdetail-container .category-input .icon-valid-flag,
  .productdetail-container .usefulLife-input .icon-valid-flag {
    display: none; }

.productForm .cblue {
  color: #006eff !important; }

.productForm .cursorp {
  cursor: pointer; }

.productForm .icon-valid-flag {
  display: none; }

.productForm .box {
  border: 1px solid #ddd;
  padding: 0;
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px; }
  .productForm .box .box-head,
  .productForm .box .box-body {
    border-bottom: 1px solid #ddd; }
    .productForm .box .box-head > div,
    .productForm .box .box-body > div {
      padding: 0 10px;
      width: 28%;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .productForm .box .box-head > div:last-child,
    .productForm .box .box-body > div:last-child {
      width: 15%; }
    .productForm .box .box-head .form-input,
    .productForm .box .box-head .form-label,
    .productForm .box .box-head .form-output,
    .productForm .box .box-body .form-input,
    .productForm .box .box-body .form-label,
    .productForm .box .box-body .form-output {
      display: block;
      /* vertical-align: top; */
      padding-bottom: 0px; }
    .productForm .box .box-head .tc-15-select,
    .productForm .box .box-body .tc-15-select {
      min-width: 0; }
    .productForm .box .box-head .tc-input-group,
    .productForm .box .box-body .tc-input-group {
      width: 100%;
      min-width: 0; }
    .productForm .box .box-head .tc-input-group .tc-input-group-addon,
    .productForm .box .box-body .tc-input-group .tc-input-group-addon {
      width: 33px; }
  .productForm .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .productForm .box .box-body:hover {
    background-color: #f7f7f7; }
  .productForm .box .wd25 > div {
    width: 25%; }
  .productForm .box .box-body > div {
    font-weight: normal; }
  .productForm .box .box-body:last-child {
    border-bottom: none; }
  .productForm .box .form-input {
    padding-bottom: 0; }
  .productForm .box .form-input-help {
    line-height: 0;
    margin-bottom: 12px; }
  .productForm .box .tc-input-group {
    line-height: 28px; }
